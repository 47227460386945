import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useLocation } from "react-router-dom";
import { VideoPlayerForAdmin } from "./VideoPlayerForAdmin";
import { getDevices } from "agora-rtc-sdk";
const ModalVideoCallPage = () => {
  const location = useLocation();
  const APP_ID = process.env.REACT_APP_AGORA_APP_ID;
  const TOKEN = location.state.callObject.call_token;
  const CHANNEL = process.env.REACT_APP_AGORA_CHANNEL_NAME;
  const client = AgoraRTC.createClient({
    mode: "rtc",
    codec: "h264",
  });
  const [users, setUsers] = useState([]);
  const [localTracks, setLocalTracks] = useState([]);
  const [muted, setMuted] = useState(false);
  const handleUserJoined = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    if (mediaType === "video") {
      setUsers((previousUsers) => [...previousUsers, user]);
    }
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const handleSwitchCamera = (user) => {
    console.log("localTracks", user);
  };

  const handleMute = () => {
    for (let localTrack of localTracks) {
      if (muted == false) {
        if (localTrack.trackMediaType == "audio") {
          localTrack?.setEnabled(false);

          setMuted(true);
        }
      } else {
        if (localTrack.trackMediaType == "audio") {
          localTrack?.setEnabled(true);
          setMuted(false);
        }
      }
    }
  };

  const handleUserLeft = (user) => {
    // setUsers((previousUsers) =>
    //   previousUsers.filter((u) => u.uid !== user.uid)
    // );
    for (let localTrack of localTracks) {
      if (localTrack) {
        if (localTrack.trackMediaType === "audio") {
          localTrack.setEnabled(false);
        }
        if (localTrack.trackMediaType === "video") {
          localTrack.setEnabled(false);
        }
      }
    }
    client.leave();
  };
  useEffect(() => {
    let track;
    client.on("user-published", handleUserJoined);
    client.on("user-left", handleUserLeft);
    client
      .join(APP_ID, CHANNEL, TOKEN, null)
      .then((uid) =>
        Promise.all([AgoraRTC.createMicrophoneAndCameraTracks(), uid])
      )
      .then(([tracks, uid]) => {
        const [audioTrack, videoTrack] = tracks;
        setLocalTracks(tracks);
        setUsers((previousUsers) => [
          ...previousUsers,
          {
            uid,
            videoTrack,
            audioTrack,
          },
        ]);
        track = tracks;
        client.publish(tracks);
      });
    return () => {
      for (let localTrack of localTracks) {
        localTrack.stop();
        localTrack.close();
      }
      client.off("user-published", handleUserJoined);
      client.off("user-left", handleUserLeft);
      client.unpublish(track).then(() => client.leave());
    };
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 200px)",
        }}
      >
        {users.map((user, index) => (
          <VideoPlayerForAdmin
            key={user.uid}
            user={user}
            index={index}
            videocallobject={location.state.callObject}
            control={() => handleMute()}
            switchCamera={() => handleSwitchCamera(user)}
            modelLeft={() => handleUserLeft()}
          />
        ))}
      </div>
    </div>
  );
};
export default ModalVideoCallPage;
